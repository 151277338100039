<template lang="pug">
  BAlert.notification(
    :show="shown"
    variant="warning"
  ) {{ tooltipMessage }}
</template>

<script>
  export default {
    props: {
      shown: {
        type: Boolean,
        default: true
      }
    },

    computed: {
      tooltipMessage() {
        return this.$t("inventory_management.sync_in_progress")
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .notification
    margin-top: 2px
    margin-bottom: 2px
</style>
